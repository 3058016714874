@import "../_animations";
@import "../_variables";

$slide: 25vw;
$slideMobile: 55vw;
$slideVertical: 90vw;

.row {
    margin: 0; }
.projects-section {
    margin: 100px 0;
    padding-top: 50px;
    z-index: 2;
    width: 100vw;
    min-height: 100vh;
    background-color: $primary;
    @media screen and ( max-width: 420px ) {
        padding-top: 20px; }
    .row1 {
        height: calc(15vw + 100px);
        margin-top: 50px; }
    .text-title {
        text-align: center;
        color: $secondary;
        letter-spacing: 6px;
        margin: 50px 10px 0 10px;
        span {
            font-size: 15px; } } }
.projects-section::before {
    z-index: -1;
    top: -5vh;
    transform-origin: top left;
    transform: skewY(-2deg);
    @media screen and ( max-width: 1100px ) {
        height: 100px; } }
.thumbnail-list {
    display: flex;
    flex-flow: wrap;
    padding: 20px 15%;
    background-color: rgba(255, 255, 255, 0.014);
    .thumbnail {
        flex: 1;
        box-sizing: border-box;
        margin: 15px;
        .active {
            transition: 0.2s ease-in;
            box-shadow: 0px 0px 50px 5px rgba(255, 255, 255, 0.315); }
        .thumbnail-wrap {
            position: relative;
            display: block;
            margin: 0 auto;
            width: 250px;
            height: 135px;
            cursor: pointer;
            &:hover {
                img {
                    filter: blur(2px);
                    transition: 0.2s ease-in; }
                .thumbnail-info {
                    visibility: visible;
                    transition: 0.2s ease-in;
                    .title {
                        opacity: 1;
                        transition: 0.2s ease-in; } } }
            img {
                display: block;
                margin: 0 auto;
                width: 250px;
                height: 135px; }
            .thumbnail-info {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                visibility: hidden;
                width: 250px;
                height: 100%;
                top: 0;
                left: 0;
                padding: 40px;
                .title {
                    font-size: 21px;
                    font-weight: bold;
                    line-height: 18px;
                    opacity: 0;
                    user-select: none;
                    color: #ffffff;
                    text-shadow: 0px 4px 13px #000000, 0px 2px 13px #000000, 0px 12px 23px #000000; } } } } }
.slider-btn {
    margin: 0 7vw;
    font-size: 2em;
    color: $fifth;
    cursor: pointer;
    &:hover {
        color: $fourthOpacity; } }
.disabled {
    color: $fourthDisabled !important;
    cursor: default !important;
    &:hover {
        color: $fourthDisabled !important; } }

#mobile-btns {
    padding-top: 15px;
    @media screen and ( max-width: 991px ) {
        margin-top: 9vw; }
    @media screen and ( max-width: 420px ) {
        margin-top: $slideMobile; }
    .slider-btn {
        margin: 0 10px; } }

.wrapper {
    position: relative;
    height: $slide/2;
    width: $slide;
    @media screen and ( max-width: 991px ) {
        width: $slideMobile;
        height: $slideMobile / 2; }
    @media screen and ( max-width: 420px ) {
        width: $slideVertical / 1.5;
        height: $slideVertical; }

    .slider {
        position: relative;
        height: $slide/2;
        width: $slide;
        transform-style: preserve-3d;
        transform: perspective(1000px) rotate3d(0, 1, 0, 0deg);
        transition: transform 0.7s;
        @media screen and ( max-width: 991px ) {
            width: $slideMobile;
            height: $slideMobile / 2; }
        @media screen and ( max-width: 420px ) {
            width: $slideVertical /1.5;
            height: $slideVertical; }
        .slide-left, .slide-right, .slide-center {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: $secondary;
            backface-visibility: hidden; }
        .slide-center {
            transform: translateZ($slide/2);
            @media screen and ( max-width: 992px ) {
                transform: translateZ($slideMobile/2); }
            @media screen and ( max-width: 420px ) {
                transform: translateZ($slideVertical/3); } }
        .slide-left {
            left: 0;
            top: 0;
            transform: rotate3d(0, 1, 0, -90deg) translateZ($slide/2);
            @media screen and ( max-width: 992px ) {
                transform: rotate3d(0, 1, 0, -90deg) translateZ($slideMobile/2); }
            @media screen and ( max-width: 420px ) {
                transform: rotate3d(0, 1, 0, -90deg) translateZ($slideVertical/3); } }
        .slide-right {
            right: 0;
            top: 0;
            transform: rotate3d(0, 1, 0, 90deg) translateZ($slide/2);
            @media screen and ( max-width: 992px ) {
                transform: rotate3d(0, 1, 0, 90deg) translateZ($slideMobile/2); }
            @media screen and ( max-width: 420px ) {
                transform: rotate3d(0, 1, 0, 90deg) translateZ($slideVertical/3); } }
        .shadow {
            height: $slide;
            width: $slide;
            bottom: 0;
            background-color: #0000004d;
            box-shadow: 0px 0px 30px 5px #00000073 !important;
            transform: rotate3d(1, 0, 0, 90deg) translateZ(-$slide/15);
            @media screen and ( max-width: 991px ) {
                width: $slideMobile;
                height: $slideMobile;
                transform: rotate3d(1, 0, 0, 90deg) translateZ(-$slideMobile/15); }
            @media screen and ( max-width: 420px ) {
                width: $slideVertical /1.5;
                height: $slideVertical / 1.5;
                transform: rotate3d(1, 0, 0, 90deg) translateZ(-$slideVertical / 1.33); }
            @media screen and ( max-height: 625px ) {
                visibility: hidden; } } } }

.container {
    margin-top: 50px;
    color: $secondary;
    font-size: 0.75em;
    .project-info {
        background: rgba(255, 255, 255, 0.05);
        padding: 20px 40px;
        border-radius: 5px;
        font-size: 0.8em;
        @media screen and ( max-width: 455px ) {
            padding: 20px;
            font-size: 1em; }
        .text-project {
            color: $fifth;
            letter-spacing: 2px; }
        .desc-project {
            text-align: justify; } } }

.animationSlideLeft {
    animation: rotateLeft 0.7s linear forwards;
    @keyframes rotateLeft {
        0%s {
            transform: perspective(1000px) rotate3d(0, 1, 0, 0deg); }
        100% {
            transform: perspective(1000px) rotate3d(0, 1, 0, 90deg); } } }

.animationSlideRight {
    animation: rotateRight 0.7s linear forwards;
    @keyframes rotateRight {
        0% {
            transform: perspective(1000px) rotate3d(0, 1, 0, 0deg); }
        100% {
            transform: perspective(1000px) rotate3d(0, 1, 0, -90deg); } } }

.text-fadeInOut {
    animation: fadeOutIn 1.4s ease-in-out; }
