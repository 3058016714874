@import "../_variables";
@import "../_animations";

.contact-content {
    position: relative;
    padding: 10px 20px;
    padding-top: 20px;
    color: $primary;
    @media screen and ( max-width: 300px ) {
        padding: 10px 0; }
    .contact-title {
        position: absolute;
        top: 20%;
        left: 10px;
        font-size: 2em;
        @media screen and ( max-width: 460px ) {
            position: relative; } }
    .input {
        position: relative;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        min-width: 100px;
        height: 35px;
        padding: 10px 20px;
        border: none;
        border-radius: 10px;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.466);
        transition: box-shadow 0.5s;
        &:focus {
            outline: none;
            box-shadow: 1px 2px 5px black; }
        @media screen and ( max-width: 460px ) {
            width: 90%; }
        @media screen and ( max-width: 300px ) {
            width: 90%; } }
    .subject-input {
        height: 35px; }
    .text-input {
        height: 120px;
        margin-top: 10px;
        resize: none;
        overflow-y: auto;
        border-radius: 5px;
        font-size: 15px; }
    ::-webkit-scrollbar {
        width: 3px; }
    ::-webkit-scrollbar-track {
        background: #f1f1f1; }
    ::-webkit-scrollbar-thumb {
        background: $fourth; }
    .contact-info {
        text-align: justify;
        max-width: 60%;
        font-size: 0.8em;
        a {
            text-decoration: none !important;
            &:hover {
                color: $fifth !important; } }
        @media screen and ( max-width: 600px ) {
            max-width: 90%; } }

    button {
        display: block;
        height: 50px;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        padding: 10px 20px;
        border-radius: 3px;
        border: none;
        background: $primary;
        color: $secondary;
        font-size: 18px;
        box-shadow: 1px 1px 1px $fourth;
        transition: box-shadow 0.2s;
        &:hover {
            box-shadow: 1px 2px 5px $fourth; } }
    .alert-box {
        position: absolute;
        right: 20%;
        top: 20%;
        z-index: 111;
        width: 200px;
        min-height: 50px;
        border-radius: 3px;
        box-shadow: 1px 2px 5px $primary;
        color: $white;
        animation: fadeIn 1s;
        .alert-content {
            position: relative;
            padding: 20px;
            .close-icon {
                position: absolute;
                cursor: pointer;
                right: 10px;
                top: 10px;
                font-size: 15px;
                &:hover {
                    color: $secondary; } }
            p {
                margin: 0;
                text-align: center;
                font-size: 0.8em; } } }
    .success {
        color: $fourth !important;
        background: #82ff62; }
    .error {
        background: $fifth; } }
