@import "../_variables";
@import "../_animations";

$descWidth: 70vw;

.home-section {
  height: 100vh;
  width: 100vw;
  .container-bg {
    height: 100%;
    width: 100%;
    background-image: url("../assets/bg-big.webp");
    background-size: calc(100vw + 400px) calc(100vh + 400px);
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s ease-out;
    @media screen and ( min-width: 420px ) {
      background-position: 50% 50%; }
    @media screen and ( max-width: 720px ) {
      background-size: auto 100vh; }
    @media screen and ( max-width: 420px ) {
      animation: backgroundFloat 5s linear infinite alternate-reverse; }
    .text-wrap {
      position: relative;
      max-width: 60%;
      font-size: 5rem;
      line-height: 1.1em;
      padding: 5px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 2px 2px 1px 1px rgba(0,0,0,0.57);
      @media screen and ( max-width: 1244px ) {
        max-width: 90%;
        font-size: 3rem; }
      @media screen and ( max-width: 500px ) {
        font-size: 2rem; }
      @media screen and ( max-width: 310px ) {
        font-size: 1.4rem; }
      @media screen and ( max-width: 240px ) {
        max-width: 70%;
        text-align: center; }
      .hand-icon {
        position: absolute;
        visibility: hidden;
        top: -50px;
        left: 45%;
        font-size: 2rem;
        color: $fourthDisabled;
        @media screen and ( max-width: 1024px ) {
          visibility: visible;
          animation: handFloat 1s linear infinite alternate-reverse; } }
      .text-name {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: bold;
        letter-spacing: 3px;
        background-image: url("../assets/bg-big.webp");
        background-size: calc(100vw + 400px) calc(100vh + 400px);
        background-position: center;
        background-attachment: fixed;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 0.1px #0000000e;
        user-select: none;
        cursor: pointer;
        transition: 0.4s ease-out;
        @media screen and ( min-width: 420px ) {
          background-position: 50% 50%; }
        @media screen and ( max-width: 720px ) {
          background-size: auto 100vh; }
        @media screen and ( max-width: 420px ) {
          animation: backgroundFloat 5s linear infinite alternate-reverse; }
        &:hover {
          letter-spacing: 5px; } }
      .description {
        padding: 5%;
        padding-bottom: 2%;
        margin: 0;
        color: $fourth;
        cursor: pointer;
        animation: zoomIn 0.3s ease-out forwards;
        @media screen and ( min-width: 1244px ) {
          padding: 5%;
          padding-bottom: 2%; }
        .description-text {
          max-height: 255px;
          padding: 0 10px;
          text-align: justify;
          font-size: 1.2rem;
          line-height: 1.2em;
          @media screen and ( max-width: 1244px ) {
            font-size: 1.0rem; }
          @media screen and ( max-width: 500px ) {
            overflow-y: auto;
            font-size: 0.8rem; }
          ::-webkit-scrollbar {
            width: 3px; }
          ::-webkit-scrollbar-track {
            background: #f1f1f1; }
          ::-webkit-scrollbar-thumb {
            background: $fourth; } }
        .colorFade {
          animation: colorFade 1.3s forwards; }
        .links {
          width: 100%;
          height: 75px;
          margin-top: 20px;
          .col {
            display: flex;
            align-items: center;
            justify-content: center;
            a {
              font-size: 3rem;
              color: $fourth !important;
              text-decoration: none !important;
              transition-duration: 0.3s;
              &:hover {
                color: $fifth !important; } } } } } } } }

.slope-divider {
  margin-top: -2vh !important;
  z-index: 11;
  h2 {
    font-family: "Almendra SC", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: bold;
    background-image: url("../assets/battle.jpg");
    background-attachment: fixed;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    user-select: none; } }

#git {
  animation: slideLeft 2s ease forwards;
  right: 10px !important; }
#lin {
  animation: slideRight 2s ease forwards;
  left: 10px !important; }

.colorFade {
  animation: colorFade 1s ease 2s forwards; }
