@import "../_variables";

.navbar-side {
    position: fixed;
    z-index: 111;
    right: 3vw;
    display: flex;
    text-align: center;
    align-items: center;
    width: 100px;
    height: 100vh;
    @media screen and ( max-width: 720px ) {
        width: 100vw;
        height: 100px; }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        @media screen and ( max-width: 720px ) {
            width: 100vw;
            height: 100px; } }
    .nav-link {
        display: block;
        width: 100%;
        margin: 30px 0;
        font-size: 20px;
        user-select: none;
        cursor: pointer;
        @media screen and ( max-width: 720px ) {
            display: inline-block;
            margin: 0;
            width: 30vw;
            height: 100px;
            font-size: 0.7em;
            text-align: right; }
        .nav-label {
            width: 100%;
            color: white;
            letter-spacing: 5px;
            opacity: 0;
            transition: 0.6s ease-in; }
        svg {
            height: 20px;
            width: 100px;
            margin-bottom: -10px;
            @media screen and ( max-width: 720px ) {
                margin-bottom: 0;
                width: 30vw; } }
        .circle-outside {
            opacity: 0.1;
            transition: 0.6s ease-in; }
        .circle-inside {
            opacity: 0.1; }
        &:hover {
            .nav-label {
                opacity: 1; }
            .circle-outside {
                opacity: 1; }
            .circle-inside {
                opacity: 1; } }
        .active {
            opacity: 1 !important; } } }
