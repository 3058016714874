@import "./_variables";

html {
  scroll-behavior: smooth; }
body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: $primary;
  margin: 0;
  font-size: 24px;
  font-family: "Titillium Web", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",monospace; }

.center {
  display: flex;
  align-items: center;
  justify-content: center; }

h1 {
  font-weight: bold;
  font-size: 8em;
  text-align: center;
  text-transform: uppercase; }

h2 {
  font-weight: bold;
  font-size: 4em;
  text-align: center;
  text-transform: uppercase;
  @media screen and ( max-width: 992px ) {
    font-size: 3em; } }

h3 {
  font-weight: bold;
  font-size: 2em;
  text-align: justify;
  @media screen and ( max-width: 720px ) {
    font-size: 1.5em; } }
