@import './_variables';

@keyframes fadeIn {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

@keyframes fadeOutIn {
    0%, 100% {
        opacity: 1; }
    50% {
        opacity: 0; } }


@keyframes slideLeft {
    0% {
        right: 250px; }
    100% {
        right: 0px; } }


@keyframes slideRight {
    0% {
        left: 250px; }
    100% {
        left: 0px; } }


@keyframes colorFade {
    0% {
        color: $white; }
    100% {
        color: $fifth; } }

@keyframes zoomIn {
    0% {
        transform: scale(0.7); }
    100% {
        transform: scale(1); } }

@keyframes backgroundFloat {
    0% {
        background-position: 49% 50%; }
    100% {
        background-position: 52% 50%; } }

@keyframes handFloat {
    0% {
        transform: translate(0, 0); }
    100% {
        transform: translate(0, 10px); } }
