@import "../_animations";
@import "../_variables";

$card_height: 300px;

.skills-section {
    position: relative;
    min-height: 100vh;
    margin-top: 200px;
    padding: 100px 10vw 150px 10vw;
    background-color: $fourth;
    ul {
        list-style: none;
        text-align: center;
        padding: 0; }
    ul li {
        position: relative;
        display: inline-block;
        text-align: center; }
    li {
        position: relative;
        display: inline-block;
        text-align: center;
        margin: 5vh 4vw;
        height: $card_height;
        width: $card_height/1.6;
        @media screen and ( max-width: 420px ) {
            height: 85vw;
            width: calc( 85vw / 1.6); }
        .scene {
            position: relative;
            height: 100%;
            width: 100%; } } }
.slope0 {
    bottom: 0;
    left: 0;
    height: 80px;
    width: 100%;
    clip-path: polygon(0 100%, 0 0, 100% 100%);
    background: $secondary;

    //&:nth-child(1)
    //    .card-front, .card-side
 }    //        background-color: $reddirty
