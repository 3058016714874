@import "../_variables";
@import "../_animations";

$slide: 25vw;
$slideMobile: 55vw;
$slideVertical: 90vw;

.slide-base {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    height: $slide/2;
    width: $slide;
    @media screen and ( max-width: 991px ) {
        width: 100%;
        height: 100%; }
    @media screen and ( max-width: 420px ) {
        background-position: top center; }
    .links-wrap {
        position: absolute;
        right: 10px;
        bottom: 10px;
        .link {
            margin-top: 10px;
            height: 40px;
            width: 40px;
            background: #3a67c728;
            border-radius: 50px;
            box-shadow: 0px 0px 1px 1px $fifth;
            &:hover {
                background-color: #e9eaec21; }
            a {
                font-size: 18px;
                color: $fifth; } } } }
